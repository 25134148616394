<script>
import Navbar from "@/components/navbar";
import Service from "@/components/services";
import Features from "@/components/features";
import Pricing from "@/components/pricing";
import Blog from "@/components/blog";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import InfoTab from "../components/infotab.vue";
import StatisticCount from "../components/statisticCount.vue";
import CreatorsService from "../services/creators.sevice";
/**
 * Index-1
 */
export default {
  components: {
    Navbar,
    Service,
    Features,
    Pricing,
    Blog,
    Contact,
    Footer,
    InfoTab,
    StatisticCount,
  },
  data() {
    return {
      ArtistList: [],
    };
  },
  created() {
    this.GetAwardedArtistsList();
  },
  methods: {
    GetAwardedArtistsList() {
      CreatorsService.GetAwardedArtistsList().then((res) => {
        this.ArtistList = res.data.result;
        console.log(this.ArtistList);
      });
    },
  },
};
</script>

<template>
  <div class="main-app">
    <Navbar />
    <div v-scroll-spy>
      <section
        class="hero-1-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/back-img4.jpg') + ')',
        }"
        id="home"
      >
        <div class="main-container">
          <div class="">
            <h4 class="creators-title">Davlat mukofoti sovrindorlari</h4>
          </div>
          <div class="content custom-scrollbar">
            <b-row class="">
              <b-col
                sm="12"
                lg="6"
                md="6"
                v-for="(item, index) in ArtistList"
                :key="index"
              >
                <div class="cardLess mt-4">
                  <div>
                    <img
                      class="artistImg"
                      :src="'data:image/png;base64,' + item.photoartistbase64"
                      alt=""
                    />
                    <!-- <img
                      class="artistImg"
                      src="@/assets/images/shaxlo.jpeg"
                      alt=""
                    /> -->
                  </div>
                  <div class="title-section2">
                    <h1 class="rewardTitle">{{ item.personname }}</h1>
                    <h1 class="rewardText">
                      {{ item.awardname }} - {{ item.awardyear }} (yilda olgan)
                    </h1>
                  </div>
                    <div class="title-section3">
                      <img
                        class="rewardImg"
                        :src="'data:image/png;base64,' + item.photobase64"
                        alt=""
                      />
                      <!-- <img
                        class="rewardImg"
                        :src="'../assets/images/medal-icon/'+item.photoid+'.png'"
                        alt=""
                      /> -->
                    </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  </div>
</template>
<style>
.title {
  width: 100%;
  text-align: center;
}
.title h1 {
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #ecf0f1;
  font-size: 19px;
  text-transform: capitalize;
}
.cardLess {
  background-image: linear-gradient(
    to right top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  width: 100%;
  height: 115px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}
.rewardImg {
  width: 40px;
  height: auto;
}
.artistImg {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
}
.rewardTitle {
  font-size: 19px;
  color: #fff;
  text-transform: uppercase;
}
.rewardText {
  font-size: 17px;
  color: #e3e3e3;
}
body {
  height: 100%;
  background-color: #ecf0f1;
  padding: 0rem;
  margin: 0;
}

</style>
